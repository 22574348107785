
.loginContainer {

	h2.loginSecond{
		margin-top: 10px;
		margin-bottom: 30px;
	}

	.control-label {

		font-weight: 200;
		font-size: 22px;
		padding-top: 0px !important;
	}

}