

.control-label {

	font-weight: 600;
	font-size: 16px;
}


.btn-switch {

	
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 17px;
    border-bottom-left-radius: 17px;

    color: $brand-color-secondary;
}
