div.footer {
  background-color: #58595b;
  margin-top: 20px;
}

img.footerlogo {
  margin-bottom: -100px !important;
}

div.footerdiv {
  margin-top: 20px;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

footer {
  color: #fff;

  	a {
  		color: #ffffff;
  		text-decoration: none;
	}

	a:hover {
	  	color: #ffffff;
	}
}

footer div.input-group {
  margin-top: 12px;
  margin-bottom: 12px;
}

.footer-copyright {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 11px;
}

